import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'

import colors from '../assets/js/colors'
import facebookLogo from '../assets/img/facebook-square-white.svg'

const Wrapper = styled.div`
  @import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption:400,700');
  
  font-family: 'PT Sans Caption', sans-serif;
	padding: 0 2rem 2rem 2rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
	min-height: 100%;	
	position: absolute; // required for min-height to work, not sure why
	color: ${colors.facebook}
`

const Header = styled.h1`
  margin: 0 0 1rem 0;
`

const Subheader = styled.h2`
  margin: 0 0 3rem 0;
  font-weight: normal;
  color: ${colors.gray}
`

const Text = styled.p`
  margin: 0 0 2rem 0;
`

const BorderText = styled.p`
  margin: 0 0 3rem 0;
  border-left: solid .5px;
  padding-left: 1rem;
  padding-right: 1rem;
`

const Button = styled.a.attrs({
  target: '_blank'
})`
  color: white;
  border-radius: 5px;
	background-color: ${colors.facebook};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  
  img {  
    margin-left: 1rem;
    height: 30px;
  }
`

const FacebookLink = ({ data: { markdownRemark: { frontmatter } } }) =>
  <Layout frontmatter={frontmatter}>
    <Wrapper>
      <Fade bottom ssrReveal>
        <div>
          {frontmatter.header && <Header>{frontmatter.header}</Header>}
          {frontmatter.subheader && <Subheader>{frontmatter.subheader}</Subheader>}
          {frontmatter.text && <Text>{frontmatter.text}</Text>}
          {frontmatter.borderText && <BorderText>{frontmatter.borderText}</BorderText>}
          <Button href={frontmatter.link}>{frontmatter.buttonText}
            <img src={facebookLogo} alt='facebook logo' />
          </Button>
        </div>
      </Fade>
    </Wrapper>
  </Layout>

export default FacebookLink

export const pageQuery = graphql`
  query FacebookLinkBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        templateKey
        title
        description
        header
        subheader
        text
        borderText
        buttonText
        link
        favicon {
          publicURL
        }
      }
    }
  }
`
